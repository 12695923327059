import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
const Our_Work = () => {
    return (
        <>
            <div className="margin"></div>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container>
                    <img src="dart media logo1.png" ></img>
                    <Nav.Link className='Edit-my-video' href="#pricing" style={{ color: 'black', fontFamily: "'Protest Riot', sans-serif;" }}>Edit My Video</Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" className="Links" ><b>What We Do</b></Nav.Link>
                            <Nav.Link href="Our_Work" className="Links" ><b>Our Work</b></Nav.Link>
                            <Nav.Link href="/Blogs" className="Links"><b>Blogs</b></Nav.Link>
                            <Nav.Link href="https://www.dartmarketing.in/" className="Links" ><b>We Also Do</b></Nav.Link>
                            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto text-center">
                            <Nav.Link href="tel:9393415155" style={{ color: 'white', backgroundColor: 'red', borderRadius: '5%' }}><b>Book a Call</b></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className='container mt-5'>
                <div className='row mb-5'>
                    <h2 className='mt-5 text-center'><b>OUR WORK</b></h2>

                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/dig6lF-oF2I?si=3qo2ghB5MerAAR28" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/XjG1-tLb-gI?si=kM3HmxaKsTwaw--P" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/QdQU-lnnquk?si=Hk6xNC-Zx0b8ZY_a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/X9oaQJibty4?si=TUQ2PpLluKiQ3Z30" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/U9Aq6Mvvxmk?si=SfHZ45QYgXDEOeDq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/voEATCvDEbU?si=X5dotFgbdDfGHFPJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/fwMqNZcJ0z4?si=w-qXX7gcGpwbAcHj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/_PIND1ZIpLc?si=LZYp4jmoYpjZZfum" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/pT7lsNqiCz0?si=g69e96LCD9aNi_GD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className='col-md-3 col-sm-12 mt-5 mb-2'>
                        <iframe width="100%" height="100%" style={{borderRadius:'15px'}} src="https://www.youtube.com/embed/hV6tMmfBUI4?si=9PUEgnr5S8vR2VSW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>

                </div>
            </div>

            <div className=" footer mt-5">
                <div className="footer1"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <center>
                                <img src="dart media logo1.png"  ></img>
                                <p>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                            </center>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Services</h3>
                            </center>
                            <p className="text-center">Trending Shorts Editing</p>
                            <p className="text-center">YouTube Vlog Editing</p>
                            <p className="text-center">Motion Graphics</p>
                            <p className="text-center">Story Board Editing</p>
                            <p className="text-center">Green Screen</p>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Contact</h3>
                            </center>
                            <p className="text-center">mobile Number</p>
                            <p className="text-center">9393415155</p>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Our_Work
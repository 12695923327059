import logo from './logo.svg';
import './App.css';
import Header from './Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Testimonial from './Testimonial';
import Our_Work from './Our_Work';
import Blogs from './Blogs';
import Online_Video_Editors_with_Edit_My_Video from './Online_Video_Editors_with_Edit_My_Video';
import Edit_your_videos_with_Edit_My_Video from './Edit_your_videos_with_Edit_My_Video';
import Why_Do_YouTubers_Need_Editors from './Why_Do_YouTubers_Need_Editors';


function App() {
  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Header />}/>
        <Route path='/Blogs' element={<Blogs/>}/>
        <Route path='/Our_Work' element={<Our_Work/>}/>
        <Route path="/Online_Video_Editors_with_Edit_My_Video" element={<Online_Video_Editors_with_Edit_My_Video />}/>
        <Route path="/Edit_your_videos_with_Edit_My_Video" element={<Edit_your_videos_with_Edit_My_Video />}/>
        <Route path="/Why_Do_YouTubers_Need_Editors" element={<Why_Do_YouTubers_Need_Editors/>}/>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;

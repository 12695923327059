import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Clock from './Clock.json'
import Lottie from 'lottie-react'
import FooterImage from './fOOTERiMAGE.json'
import Video from './VideoEditingAnimation.json'
import Video1 from './Video1.mp4'
import Video2 from './Video2.mp4'
import Video3 from './Video3.mp4'
import Video4 from './Video4.mp4'
import Video5 from './Video5.mp4'
import Video6 from './Video6.mp4'
import Video7 from './Video7.mp4'
import Video8 from './Video8.mp4'
import Video9 from './Video9.mp4'

const Header = () => {


    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container>
                    <img src="dart media logo1.png" ></img>
                    <Nav.Link className='Edit-my-video' href="#pricing" style={{ color: 'black', fontFamily: "'Protest Riot', sans-serif;" }}>Edit My Video</Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="#WeDo" className="Links"><b>What We Do</b></Nav.Link>
                            <Nav.Link href="/Our_Work" className="Links"><b>Our Work</b></Nav.Link>
                            <Nav.Link href="/Blogs" className="Links"><b>Blogs</b></Nav.Link>

                            <Nav.Link href="https://www.dartmarketing.in/" className="Links"><b>We Also Do</b></Nav.Link>
                            {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto text-center">
                            <Nav.Link href="tel:9393415155" style={{ color: 'white', backgroundColor: 'red', borderRadius: '5%' }}><b>Book a Call</b></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="margin"></div>
            <Carousel className="homeclass" variant='dark' pause={false} indicators={false}>
                <Carousel.Item className=" homeclass1">
                    <img
                        className="d-block w-100"
                        src="web01.png"
                        alt="First slide"
                    // style={{animation:'zoom 15s'}}
                    />
                    {/* <Carousel.Caption>
                        <h4>First slide label</h4>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="web02.png"
                        alt="SECOND slide"
                    // style={{animation:'zoom 15s'}}
                    />
                    {/* <Carousel.Caption>
                        <h4>First slide label</h4>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="web031.png"
                        alt="THIRD slide"
                    />
                    {/* <Carousel.Caption>
                        <h4>First slide label</h4>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="web04.png"
                        alt="FOURTH slide"
                    />
                    {/* <Carousel.Caption>
                        <h4>First slide label</h4>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>

            <div className="container mt-3" id="WeDo">
                <div className="row text-center Take_Brand" style={{ backgroundColor: 'rgb(210, 210, 210)', }}>

                    <div className="p-5 mt-5" >
                        <p>Take your brand to the next level</p>
                        <h2>Have some questions?</h2>
                        <h2>Feel free to book a call with us.</h2>
                        <Link><button className="Book_A_Call">Book a Call</button></Link>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12 mb-2">
                        <div className="card mb-4" style={{ border: '1.5px solid rgb(210, 210, 210)', borderRadius: '15px' }}>
                            <img src="WhatsApp Image 2024-02-19 at 11.35.16 AM (3).jpeg" alt="" style={{ borderRadius: '15px' }}></img>
                            <h4 className="text-center mt-4">Trending Shorts Editing</h4>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12 mb-2">
                        <div className="card mb-4" style={{ border: '1.5px solid rgb(210, 210, 210)', borderRadius: '15px' }}>
                            <img src="WhatsApp Image 2024-02-19 at 11.35.16 AM (2).jpeg" alt="" style={{ borderRadius: '15px' }}></img>
                            <h4 className="text-center mt-4">YouTube Vlog Editing</h4>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                        <div className="card mb-4" style={{ border: '1.5px solid rgb(210, 210, 210)', borderRadius: '15px' }}>
                            <img src="WhatsApp Image 2024-02-19 at 11.35.16 AM (1).jpeg" alt="" style={{ borderRadius: '15px' }}></img>
                            <h4 className="text-center mt-4">Motion Graphics</h4>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                        <div className="card mb-4" style={{ border: '1.5px solid rgb(210, 210, 210)', borderRadius: '15px' }}>
                            <img src="WhatsApp Image 2024-02-19 at 11.35.16 AM.jpeg" alt="" style={{ borderRadius: '15px', height: '308px' }}></img>
                            <h4 className="text-center mt-4">Story Board Editing</h4>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="Container footer mt-5" >
                  <img src="best-video-editing.png"></img>
                <div className="row">
                    <div className="content col-md-4 col-sm-12">
                        {/* <img src="dart media logo1.png"></img> */}
            {/* <p>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                    </div>
                </div>
            </div> */}


            <div className="container mt-5 mb-5 portfolio">
                <center >
                    <h3><b>Make Your Videos Like This</b></h3>
                    <p className="mt-4" style={{ color: 'rgb(162, 157, 157)' }}>Strategically Crafted Branded Signature Edits Uniquely Designed for Our Partners and Their Audiences</p>
                </center>
                <div className="row mb-5 mt-5">
                    <div className="col-md-4 col-sm-12 " >
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video1} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video2} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video3} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                </div>
                <div className="row mb-5 ">
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video4} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video5} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video6} className="Source" type="video/mp4"></source>
                        </video>
                    </div>

                </div>
                <div className="row mb-5 ">
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video7} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video8} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                        <video className="Videos" width="100%" height="" controls style={{ borderRadius: '15px' }}>
                            <source src={Video9} className="Source" type="video/mp4"></source>
                        </video>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <h3 className="text-center"><b>What We Do</b></h3>
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Linear Video Editing</h4>
                            </center>
                            {/* <h4 style={{paddingLeft:'3%'}}> Focus on your business</h4>
                        <p style={{paddingLeft:'3%'}}>Automate your content creation.</p> */}
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Live Editing</h4>
                            </center>
                        </div>
                    </div> */}
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Insert Editing</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Standard Cut</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Montage</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Avs Video Editing</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Cutaways</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Assemble Editing</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Rough Cut</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Collaborative Editing</h4>
                            </center>
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}>Bespoke Editing</h4>
                            </center>
                        </div>
                    </div> */}
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}>Simple Cutting</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}> Parallel Editing</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}>Cutting On Action</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}>Jump Cut</h4>
                            </center>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <div className="card mt-5">
                            <center>
                                <Lottie animationData={Video} loop={true} style={{ width: '35%', padding: '2%', alignItems: 'center' }} />
                                <h4 style={{ paddingLeft: '3%' }}>Green Screen</h4>
                            </center>
                        </div>
                    </div>
                </div>
            </div>


            {/* <footer></footer> */}

            <div className=" footer mt-5">
                <div className="footer1"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <center>
                                <img src="dart media logo1.png"  ></img>
                                <p>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                            </center>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Services</h3>
                            </center>
                            <p className="text-center">Trending Shorts Editing</p>
                            <p className="text-center">YouTube Vlog Editing</p>
                            <p className="text-center">Motion Graphics</p>
                            <p className="text-center">Story Board Editing</p>
                            <p className="text-center">Green Screen</p>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Contact</h3>
                            </center>
                            <p className="text-center">mobile Number</p>
                            <p className="text-center">9393415155</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
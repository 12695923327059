import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Online_Video_Editors_with_Edit_My_Video = () => {
    return (
        <>
            <div className="margin"></div>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container>
                    <img src="dart media logo1.png" ></img>
                    <Nav.Link className='Edit-my-video' href="#pricing" style={{ color: 'black', fontFamily: "'Protest Riot', sans-serif;" }}>Edit My Video</Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" className="Links" ><b>What We Do</b></Nav.Link>
                            <Nav.Link href="Our_Work" className="Links" ><b>Our Work</b></Nav.Link>
                            <Nav.Link href="/Blogs" className="Links"><b>Blogs</b></Nav.Link>
                            <Nav.Link href="https://www.dartmarketing.in/" className="Links" ><b>We Also Do</b></Nav.Link>
                            {/* <NavDropdown h6="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto text-center">
                            <Nav.Link href="tel:9393415155" style={{ color: 'white', backgroundColor: 'red', borderRadius: '5%' }}><b>Book a Call</b></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className='container mt-5'>
                <div className='row mt-5 mb-4'>
                    <center className='mt-5'><h2 >Online Video Editors with <a href='https://editmyvideo.in/'>EditMyVideo.in</a></h2></center>
                </div>

                <div className='row mt-5'>

                   <div className='col-md-7 col-sm-12'>
                   <p style={{color:'gray'}}>Welcome to <a href='https://editmyvideo.in/'>Edit My Video</a> – Your go-to destination for professional video editing services! Whether you're a thriving company, a dynamic YouTuber, a Social Media Influencer, or a passionate vlogger, we've got your video editing needs covered.</p>
                    <h3 style={{ color: 'red' }}>Why Choose Us? </h3>
                    <h5><b><a href='https://editmyvideo.in/'>Expert Video Editors:</a></b></h5>
                    <p style={{color:'gray'}}>Our team comprises skilled and experienced video editors who are dedicated to transforming your raw footage into captivating visual stories. From corporate videos to vlogs, we understand the unique requirements of diverse projects.</p>
                    <h5><b><a href='https://editmyvideo.in/'>Tailored Solutions:</a></b></h5>
                    <p style={{color:'gray'}}>We recognize that every project is unique. Our video editing services are customizable to suit your specific needs, ensuring that the final product aligns perfectly with your vision and objectives.</p>
                
                   </div>
                   <div className='col-md-5 col-sm-12'>
                    <img className='blog_image ' src='video-edit3.jpg' alt='' style={{width:'100%'}}></img>
                   </div>
                   <h5><b><a href='https://editmyvideo.in/'>Quick Turnaround:</a></b></h5>
                    <p style={{color:'gray'}}>Time is of the essence, and we value yours. Our efficient workflow allows us to deliver high-quality edited videos within a timeframe that suits your schedule, without compromising on quality.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Affordable Packages:</a></b></h5>
                    <p style={{color:'gray'}}>Quality video editing doesn't have to break the bank. Explore our budget-friendly packages designed to accommodate various projects, whether you're a start-up, a growing business, or an established content creator.</p>

                    <h3 style={{ color: 'red' }}>Our Services:</h3>
                    <h5><b><a href='https://editmyvideo.in/'>Corporate Video Editing:</a></b></h5>
                    <p style={{color:'gray'}}>Impress your clients and stakeholders with professionally edited corporate videos that enhance your brand image and communication.</p>

                    <h5><b><a href='https://editmyvideo.in/'>YouTube Content Editing:</a></b></h5>
                    <p style={{color:'gray'}}>Elevate your YouTube channel with engaging and polished videos that keep your audience hooked from start to finish.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Social Media Content Editing:</a></b></h5>
                    <p style={{color:'gray'}}>Stay on top of the social media game with visually appealing and shareable content tailored for platforms like Instagram, Facebook, Twitter, and more.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Influencer Video Editing:</a></b></h5>
                    <p style={{color:'gray'}}>Enhance your online presence and collaborations by presenting your content in a visually striking and cohesive manner.</p>
                    
                    <h5><b><a href='https://editmyvideo.in/'>Vlog Editing:</a></b></h5>
                    <p style={{color:'gray'}}>Share your adventures and stories through seamlessly edited vlogs that captivate and retain viewer interest.</p>

                    <h3 style={{ color: 'red' }}>How It Works:</h3>
                    <h5><b><a href='https://editmyvideo.in/'>Submit Your Footage:</a></b></h5>
                    <p style={{color:'gray'}}>Share your raw footage with us securely through our user-friendly platform.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Discuss Your Vision:</a></b></h5>
                    <p style={{color:'gray'}}>Collaborate with our team to convey your editing preferences, style, and any specific requirements you may have.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Review and Approve:</a></b></h5>
                    <p style={{color:'gray'}}>Receive a preview of your edited video, provide feedback, and we'll make revisions until you are completely satisfied.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Download Your Edited Video:</a></b></h5>
                    <p style={{color:'gray'}}>Once the editing process is complete, easily download your polished video ready for sharing or publishing.</p>

                   
                    <p style={{color:'gray'}}>Transform your video content into a masterpiece with Edit My Video Service. Let's bring your vision to life! Contact us today to get started.</p>

                </div>
            </div>

            {/* <footer></footer> */}

            <div className=" footer mt-5">
                <div className="footer1"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <center>
                                <img src="dart media logo1.png"  ></img>
                                <p style={{color:'gray'}}>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                            </center>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Services</h3>
                            </center>
                            <p className="text-center">Trending Shorts Editing</p>
                            <p className="text-center">YouTube Vlog Editing</p>
                            <p className="text-center">Motion Graphics</p>
                            <p className="text-center">Story Board Editing</p>
                            <p className="text-center">Green Screen</p>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Contact</h3>
                            </center>
                            <p className="text-center">mobile Number</p>
                            <p className="text-center">9393415155</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Online_Video_Editors_with_Edit_My_Video
import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Blogs = () => {
    return (
        <>
            <div className="margin"></div>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container>
                    <img src="dart media logo1.png" ></img>
                    <Nav.Link className='Edit-my-video' href="#pricing" style={{ color: 'black', fontFamily: "'Protest Riot', sans-serif;" }}>Edit My Video</Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" className="Links" ><b>What We Do</b></Nav.Link>
                            <Nav.Link href="Our_Work" className="Links" ><b>Our Work</b></Nav.Link>
                            <Nav.Link href="/Blogs" className="Links"><b>Blogs</b></Nav.Link>
                            <Nav.Link href="https://www.dartmarketing.in/" className="Links" ><b>We Also Do</b></Nav.Link>
                            {/* <NavDropdown h6="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto text-center">
                            <Nav.Link href="tel:9393415155" style={{ color: 'white', backgroundColor: 'red', borderRadius: '5%' }}><b>Book a Call</b></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className='container mt-5'>
                <div className='row mt-5'>
                    <center className='mt-5'><h2 >BLOGS</h2></center>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-3'>
                        <div className='card text-center'>
                            <div className='mt-2 p-2'>
                                <img src='designers-working-with-montage-team.jpg' alt='' style={{width:'100%'}}></img>
                                <h6 className='mt-3'><a href='/Online_Video_Editors_with_Edit_My_Video' style={{ color: 'black' }}>Online Video Editors with </a><a href='https://editmyvideo.in/'>EditMyVideo.in</a></h6>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card text-center'>
                            <div className='mt-2 p-2'>
                                <img src='video-edit2.jpg' alt='' style={{width:'100%'}}></img>
                                <h6 className='mt-3'><a href='/Edit_your_videos_with_Edit_My_Video' style={{ color: 'black' }}>Edit your videos with </a><a href='https://editmyvideo.in/'>Editmyvideo.in</a></h6>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='card text-center'>
                            <div className='mt-2 p-2'>
                                <img src='video-edit3.jpg' alt='' style={{width:'100%'}}></img>
                                <h6 className='mt-3'><a href='/Why_Do_YouTubers_Need_Editors' style={{ color: 'black' }}>Why Do YouTubers Need Editors? </a></h6>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            
            {/* <footer></footer> */}

            <div className=" footer mt-5">
                <div className="footer1"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <center>
                                <img src="dart media logo1.png"  ></img>
                                <p>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                            </center>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Services</h3>
                            </center>
                            <p className="text-center">Trending Shorts Editing</p>
                            <p className="text-center">YouTube Vlog Editing</p>
                            <p className="text-center">Motion Graphics</p>
                            <p className="text-center">Story Board Editing</p>
                            <p className="text-center">Green Screen</p>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Contact</h3>
                            </center>
                            <p className="text-center">mobile Number</p>
                            <p className="text-center">9393415155</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs
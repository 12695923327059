import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Edit_your_videos_with_Edit_My_Video = () => {
    return (
        <>
            <div className="margin"></div>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" fixed="top">
                <Container>
                    <img src="dart media logo1.png" ></img>
                    <Nav.Link className='Edit-my-video' href="#pricing" style={{ color: 'black', fontFamily: "'Protest Riot', sans-serif;" }}>Edit My Video</Nav.Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" className="Links" ><b>What We Do</b></Nav.Link>
                            <Nav.Link href="Our_Work" className="Links" ><b>Our Work</b></Nav.Link>
                            <Nav.Link href="/Blogs" className="Links"><b>Blogs</b></Nav.Link>
                            <Nav.Link href="https://www.dartmarketing.in/" className="Links" ><b>We Also Do</b></Nav.Link>
                            {/* <NavDropdown h6="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
                        </Nav>
                        <Nav className="ms-auto text-center">
                            <Nav.Link href="tel:9393415155" style={{ color: 'white', backgroundColor: 'red', borderRadius: '5%' }}><b>Book a Call</b></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className='container mt-5'>
                <div className='row mt-5'>
                    <center className='mt-5'><h2 >Edit your videos with <a href='https://editmyvideo.in/'>Editmyvideo.in</a></h2></center>
                </div>

                <div className='row mt-5'>

                    <div className='col-md-7 col-sm-12'>
                        <p style={{ color: 'gray' }}>Welcome to Edit My Video, your premier destination for professional video editing services tailored to meet the needs of your company, YouTube channel, social media influence, or vlog. Our team of experienced editors is dedicated to helping you elevate your content and stand out in today's digital landscape.</p>
                        <p style={{ color: 'gray' }}>Whether you're a startup looking to create compelling promotional videos, a seasoned YouTuber aiming to enhance your production value, a social media influencer seeking to captivate your audience, or a passionate vlogger wanting to bring your stories to life, Edit My Video has the expertise and creativity to bring your vision to fruition.</p>
                        <h3 style={{ color: 'red' }}>
                            Why Choose Edit My Video?</h3>
                        <h5><b><a href='https://editmyvideo.in/'>Expert Editors:</a></b></h5>
                        <p style={{ color: 'gray' }}>Our editors are skilled professionals with years of experience in the industry. They have a keen eye for detail and a passion for storytelling, ensuring that your videos are polished to perfection.</p>


                    </div>
                    <div className='col-md-5 col-sm-12'>
                        <img className='blog_image ' src='video-edit2.jpg' alt='' style={{ width: '100%' }}></img>
                    </div>
                    <h5><b><a href='https://editmyvideo.in/'>Customized Solutions:</a></b></h5>
                        <p style={{ color: 'gray' }}>We understand that every project is unique, which is why we offer personalized editing solutions tailored to your specific needs and preferences. Whether you require basic editing services or complex post-production work, we've got you covered.</p>
                    <h5><b><a href='https://editmyvideo.in/'>Quick Turnaround:</a></b></h5>
                    <p style={{ color: 'gray' }}>We value your time and strive to deliver fast, efficient service without compromising on quality. Our streamlined workflow ensures that your edited videos are ready for delivery within the shortest possible timeframe.</p>

                    <h5><b><a href='https://editmyvideo.in/'>Affordable Pricing:</a></b></h5>
                    <p style={{ color: 'gray' }}>Quality editing shouldn't break the bank. At Edit My Video, we offer competitive pricing plans designed to fit any budget. Plus, with our transparent pricing structure, you'll always know exactly what you're paying for.</p>


                    <h5><b><a href='https://editmyvideo.in/'>Exceptional Customer Support:</a></b></h5>
                    <p style={{ color: 'gray' }}>Your satisfaction is our top priority. Our friendly customer support team is here to assist you every step of the way, from initial inquiry to final delivery. We're committed to providing prompt, professional service that exceeds your expectations.</p>
                    <p style={{ color: 'gray' }}>
                        Ready to take your videos to the next level? Contact Edit My Video today to learn more about our editing services and get started on your next project. Let us help you unleash the full potential of your content and make a lasting impression on your audience.</p>
                </div>
            </div>

            {/* <footer></footer> */}

            <div className=" footer mt-5">
                <div className="footer1"></div>
                <div className="container content">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <center>
                                <img src="dart media logo1.png"  ></img>
                                <p>If you’re a busy creator with no time to edit or post consistently on social media but still recognize the need to grow your brand faster, then this is for you.</p>
                            </center>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Services</h3>
                            </center>
                            <p className="text-center">Trending Shorts Editing</p>
                            <p className="text-center">YouTube Vlog Editing</p>
                            <p className="text-center">Motion Graphics</p>
                            <p className="text-center">Story Board Editing</p>
                            <p className="text-center">Green Screen</p>
                        </div>
                        <div className="col-md-4 col-sm-12 Services">
                            <center>
                                <h3 className="footer_heading">Contact</h3>
                            </center>
                            <p className="text-center">mobile Number</p>
                            <p className="text-center">9393415155</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Edit_your_videos_with_Edit_My_Video